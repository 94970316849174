import React from "react"
import { translate } from "../../utils/language"
import { imageUrl } from "../../utils/path"
import { renderMarkdown } from "../../utils/rendermarkdown"

export const BlogpostBildMitText = ({data, language}) => {
    return <div>
        <h2>{translate(language, data.Titel_deutsch, data.Titel_englisch)}</h2>
        <div className="bild-mit-text-blogpost-container">
            <div className="bild-mit-untertitel">
                <img className="bild-mit-text-blogpost-image" src={imageUrl(data.bild.Bild.url)} alt={translate(language, data.bild.Deutsch, data.bild.Englisch)}/>
                <p className="small caption">{(translate(language, data.bild.Untertitel_de, data.bild.Untertitel_en))}</p>
            </div>
            <p className="bild-mit-text-blogpost-text">{renderMarkdown(translate(language, data.Deutsch, data.Englisch))}</p>
        </div>
    </div>
    }
