import React from "react";
import { translate } from "../../utils/language";
import { imageUrl } from "../../utils/path";

export const BlogpostGallerie = ({ data, language }) => {
  return (
    <div className="images-side-by-side">
      {data.Bilder.map((b) => {
        return (
          b.Bild && (
            <div>
              <img
                className="blogpost-images-single"
                src={imageUrl(b.Bild.url)}
                alt={translate(language, b.Deutsch, b.Englisch)}
              />
              <p className="small caption">{(translate(language, b.Untertitel_de, b.Untertitel_en))}</p>
            </div>
          )
        );
      })}
    </div>
  );
};
